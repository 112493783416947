<template>
  <div>
    <navigation show-nav @open="onNavClick" :show-send-invite="currentEvent && !currentEvent.canceled" />
    <div class="col-md-10 mx-auto">
      <div class="mx-0 px-0 d-flex justify-content-between flex-column flex-md-row">
        <div class="form-inline">
          <select class="form-control mr-sm-4 mb-4 custom-select" v-model="activeDate" @input="setActiveDate" ref="select">
            <option value="" selected>Choose date</option>
            <option v-for="date in (showHistory ? dates : onlyFutureDates)" :key="date" :value="date">
              {{ date }}
            </option>
            <option value="history">
                {{showHistory ? 'Hide History' : 'Show History'}}
            </option>
          </select>
<!--          <select class="form-control mb-4 mr-sm-4 custom-select" v-model.number="activeEventIndex" v-if="activeDate">
            <option value="" selected>Choose location</option>
            <option v-for="(event, index) in eventsWithActiveDate" :key="event.key" :value="String(index)">
              {{ getLocationName(event.location) }}
            </option>
          </select>-->
          <h3 v-if="currentEvent" class="mb-4 mr-sm-4" style="color: #fff">
            {{ currentEvent.title + ', ' }}<span style="color: #949494">{{ formatEventTime(currentEvent.date )}} in {{ getLocationName(currentEvent.location) }}</span>
          </h3>

          <span style="color: #dc3545; font-size: 20px;" class="mb-4 ml-auto ml-sm-0" v-if="currentEvent && currentEvent.canceled">
            Cancelled
          </span>
        </div>


        <div class="ml-md-auto ml-0">
          <button
              v-if="currentEvent && !currentEvent.canceled && !dateInPast(currentEvent.date)"
              style="min-width: 120px;"
              class="btn btn-outline-success mb-4 ml-sm-0 mr-2"
              @click="createEventLink"
          >
            Link to event
          </button>
          <button
              v-if="currentEvent && !currentEvent.canceled && !dateInPast(currentEvent.date)"
              style="min-width: 120px;"
              class="btn btn-outline-danger mb-4"
              @click="showModals.cancelEvent = true"
          >
            Cancel event
          </button>
        </div>
      </div>
      <hall
        v-if="currentEvent"
        class="hall mb-4"
        admin
        :event="currentEvent"
        @select-seat="selectSeat" />
      <invites-table
        :event="currentEvent"
        v-if="currentEvent"
        @click-invite="invite => !invite.removed && selectSeat({
          ...invite.seat,
          confirmed: invite.confirmed,
          registerBy: invite.name,
          registerByEmail: invite.email,
          inviteTime: invite.time,
          checkedIn: invite.checkedIn,
          inviteKey: invite.key,
        })"
      />
    </div>

    <modal title="Link copied!"  v-if="showModals.linkCopied" />
    <send-invite-modal
      v-if="showModals.sendInvite"
      :event="currentEvent"
      :seat="inviteSeat"
      @add-photo="addPhotoToInvite"
      @close="closeSendInvite"
    />
    <registered-seat-modal
      v-if="showModals.registeredSeat"
      :event="currentEvent"
      :seat="inviteSeat"
      @add-photo="inviteSeat.fileUrl = $event"
      @close="closeRegisteredSeat" />
    <create-event-modal v-if="showModals.createEvent" @close="closeCreateEvent" />
    <locked-seat-modal
      v-if="showModals.lockedSeat"
      :event="currentEvent"
      :seat="inviteSeat"
      @add-photo="inviteSeat.fileUrl = $event"
      @close="showModals.lockedSeat = false"
    />
    <cancel-event-modal
      v-if="showModals.cancelEvent"
      :event="currentEvent"
      @add-photo="inviteSeat.fileUrl = $event"
      @close="showModals.cancelEvent = false;"
    />
  </div>
</template>

<script>
import { reactive, ref, computed, nextTick } from 'vue';
import InvitesTable from './InvitesTable.vue';
import Hall from '../hall/Hall';
import Navigation from '../Navigation.vue';
import SendInviteModal from './SendInviteModal.vue';
import CreateEventModal from './CreateEventModal.vue';
import {useEvents, useLinks, useLocations} from '@/store';
import RegisteredSeatModal from '@/components/admin/RegisteredSeatModal.vue';
import LockedSeatModal from '@/components/admin/LockedSeatModal.vue';
import {formatDate, formatTime, getDateFromStr, isDateInPast} from '@/lib/date';
import {auth} from "@/lib/api";
import { useRouter } from 'vue-router';
import CancelEventModal from "@/components/admin/CancelEventModal";
import Modal from "@/components/ui/Modal";

export default {
  name: 'Admin',
  components: {
    Modal,
    CancelEventModal,
    LockedSeatModal,
    RegisteredSeatModal,
    CreateEventModal,
    SendInviteModal,
    Navigation,
    Hall,
    InvitesTable,
  },
  setup() {
    const { events } = useEvents();
    const activeEventIndex = ref('');
    const router = useRouter();
    const inviteSeat = ref(null);
    const select = ref()
    const currentEvent = computed(() => eventsWithActiveDate.value[activeEventIndex.value]);

    const showHistory = ref(false)

    const showModals = reactive({
      sendInvite: false,
      createEvent: false,
      registeredSeat: false,
      lockedSeat: false,
      cancelEvent: false,
      linkCopied: false,
    });
    const onNavClick = async link => {
      switch (link) {
        case 'send-invite':
          showModals.sendInvite = true;
          break;
        case 'create-event':
          showModals.createEvent = true;
          break;
        case 'exit':
          await auth.signOut();
          router.push({ name: 'sign-in' });
          break;
        default:
          break;
      }
    };

    const closeCreateEvent = () => {
      showModals.createEvent = false;
    };

    const closeRegisteredSeat = () => {
      showModals.registeredSeat = false;
    };
    const selectSeat = seat => {
      inviteSeat.value = seat;
      if (seat.locked) {
        showModals.lockedSeat = true;
      } else if (!seat.confirmed && !seat.registerBy && !currentEvent.value.canceled) {
        showModals.sendInvite = true;
      } else if (seat.confirmed || seat.registerBy) {
        showModals.registeredSeat = true;
      }
    };
    const addPhotoToInvite = url => {
      inviteSeat.value.photoUrl = url;
    }
    const closeSendInvite = () => {
      showModals.sendInvite = false;
      inviteSeat.value = null;
    };

    const format = dateStr => formatDate(getDateFromStr(dateStr));
    const formatEventTime = dateStr => formatTime(getDateFromStr(dateStr));

    const dates = computed(() => [
      ...new Set(events.value.map(event => format(event.date)))
      //...new Set(events.value)
    ]);
    const activeDate = ref('');
    const eventsWithActiveDate = computed(() =>

      events.value.filter(event => format(event.date) === activeDate.value)
       // events.value.filter(event =>return format(event.date) === activeDate.value);
    );
    const { getLocationByKey } = useLocations();
    const getLocationName = key => getLocationByKey(key).name;

    const dateInPast = dateStr => {
      return isDateInPast(getDateFromStr(dateStr))
    }

    const onlyFutureDates = computed(() => [
        ...new Set(
          events.value
              .filter(event => !dateInPast(event.date))
              .map(event => format(event.date))
              //.map((event) => console.log(event.date))

        )
    ])
    //console.log(onlyFutureDates);
    const setActiveDate = (event) => {
      if (event.target.value === 'history') {
        event.preventDefault()
        event.stopPropagation()
        showHistory.value = !showHistory.value
        nextTick(() => {
          if (select.value) {
            const event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, true, window);
            select.value.dispatchEvent(event);
          }
        })
        return
      }
      activeEventIndex.value = 0
      activeDate.value = event.target.value;
      //console.log(event.target)
    }

    const { createLink } = useLinks()
    const copy = text => {
      const input = document.createElement('input');
      input.setAttribute('value', text);
      document.body.appendChild(input);
      input.select();
      const result = document.execCommand('copy');
      document.body.removeChild(input);
      return result;
    }
    const createEventLink = async () => {
      if (currentEvent.value) {
        const res = await createLink({
          event: currentEvent.value.key
        })
        const link = `${window.location.host}/client?link=${res.key}`
        copy(link)
        showModals.linkCopied = true
        setTimeout(() => {
          showModals.linkCopied = false
        }, 2000)
      }
    }

    return {
      events,
      activeEventIndex,
      showModals,
      onNavClick,
      inviteSeat,
      selectSeat,
      closeSendInvite,
      closeCreateEvent,
      closeRegisteredSeat,
      currentEvent,
      dates,
      activeDate,
      eventsWithActiveDate,
      format,
      getLocationName,
      setActiveDate,
      addPhotoToInvite,
      dateInPast,
      createEventLink,
      formatEventTime,
      showHistory,
      onlyFutureDates,
      select,
    };
  }
};
</script>

<style scoped>
.hall {
  border: 2px #343a40 solid;
}
</style>