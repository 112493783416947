import { ref } from 'vue';

export const useModal = (init = false) => {
  const isShowModal = ref(init);
  const closeModal = () => {
    isShowModal.value = false;
  };
  const openModal = () => {
    isShowModal.value = true;
  };
  const switchModal = () => {
    isShowModal.value = !isShowModal.value;
  };

  return {
    isShowModal,
    closeModal,
    openModal,
    switchModal,
  };
};