import { createApp } from 'vue';
import App from './App.vue'
import router from './router';
import { provideModules } from './store';

const init = async () => {
  const app = createApp(App);
  await provideModules(app);
  app
    .use(router)
    .mount('#app');
};

init();
