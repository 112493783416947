<template>
  <div
    class="seat"
    :class="`seat-${seatStatus}`"
    :style="styles"
  >
    {{ seat.seat }}
  </div>
</template>

<script>
import { computed } from 'vue';
import {useRoute} from "vue-router";

export default {
  name: 'Seat.vue',
  props: {
    seat: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const route = useRoute()
    const isClient = computed(() => route.path.includes('client'))

    const seatStatus = computed(() => {
      const { seat } = props;
      if (isClient.value && (seat.confirmed || seat.registerBy || seat.locked)) {
        return 'client-locked';
      }
      if (seat.confirmed) {
        return 'confirmed';
      }
      if (seat.registerBy) {
        return 'request';
      }
      if (seat.locked) {
        return 'locked';
      }
      return 'free';
    });

    const styles = computed(() => {
      return {
        margin: props.seat.margin ? props.seat.margin : '2px',
        transform: props.seat.rotation ? `rotate(${props.seat.rotation}deg)` : undefined
      }
    })

    return {
      seatStatus,
      styles,
      isClient,
    }
  },
};
</script>

<style scoped>
.seat {
  margin-right: 1px;
  height: 43px;
  width: 43px;
  background-size: 43px 43px;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .seat {
    height: 35px;
    width: 35px;
    background-size: 35px 35px;
    font-size: 10px;
  }
}
@media screen and (max-width: 400px) {
  .seat {
    height: 30px;
    width: 25px;
    background-size: 25px 30px;
    font-size: 8px;
  }
}
.seat-free {
  background-image: url(../../assets/seat-free.svg);
  color: #000
}
.seat-confirmed {
  background-image: url(../../assets/seat-confirmed.svg);
  color: #eeeeee;
}
.seat-locked {
  background-image: url(../../assets/seat-locked.svg);
  color: #eeeeee;
}
.seat-request {
  background-image: url(../../assets/seat-request.svg);
  color: #eeeeee;
}
.seat-client-locked {
  background-image: url(../../assets/seat-client-locked.svg);
  color: #000
}
</style>