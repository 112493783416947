<template>
  <div>
    <div
      v-for="line in seats"
      :key="line.id"
      class="seats-line"
    >
      <span v-if="showLineLeft">{{ line[0].line }}</span>
      <seat
        v-for="seat in line"
        :key="seat.id"
        :seat="seat"
        @click="selectSeat(seat)"
      />
    </div>
  </div>
</template>

<script>
import Seat from './Seat.vue';

export default {
  name: 'Sector',
  components: {
    Seat
  },
  props: {
    seats: { type: Array, default: () => [{}] },
    showLineLeft: { type: Boolean, default: false },
    showLineRight: { type: Boolean, default: false },
    admin: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const selectSeat = seat => {
      emit('select-seat', seat);
    };

    return {
      selectSeat,
    };
  },
}
</script>

<style scoped>
.seats-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  max-height: 50px;
}

@media screen and (max-width: 500px) {
  .seats-line {
    font-size: 12px;
  }
}

.seats-line > span {
  margin-left: 10px;
  margin-right: 10px;
}
</style>