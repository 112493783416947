<template>

  <modal :title="isEventCreated ? 'Event successfully created!' : 'Create Event'" @close="$emit('close')">

    <h5 v-if="errorDate" class="error">There is already an event with the same time on this day, change the time or choose a different date</h5>


    <form @submit.prevent="create" v-if="!isEventCreated">
      <div class="form-group">
        <label for="titleInput">Title</label>
        <input
          v-model="title"
          type="text"
          class="form-control"
          id="titleInput"
          placeholder="Title"
        >
      </div>

      <div class="form-group">
        <label for="dateInput">Date</label>
        <flat-pickr
          id="dateInput"
          :value="date"
          class="form-control"
          :config="{
            enableTime: true,
            dateFormat: 'm/d/y h:i K',
            minDate: 'today',
          }"
          @on-change="onDateChange"
        />
      </div>
<!--        <div class="form-group ml-4">-->
<!--          <label for="locationInput">Location</label>-->
<!--          <select v-model="locationIndex" id="locationInput" class="form-control custom-select">-->
<!--            <option v-for="(location, index) in locations" :key="location.key" :value="String(index)">-->
<!--              {{ location.name }}-->
<!--            </option>-->
<!--          </select>-->
<!--        </div>-->
      <button class="btn btn-block btn-primary mt-4" :disabled="!title.length || !date">Apply</button>
    </form>
    <div v-else>
      <button class="btn btn-block btn-success mt-4" @click="close">Close</button>
    </div>
  </modal>
</template>

<script>
import { ref } from 'vue';
import Modal from '../ui/Modal';
import {useEvents, useLocations} from "@/store";
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
//import {formatDate, getDateFromStr} from "@/lib/date";


export default {
  name: 'CreateEventModal',
  components: { Modal, FlatPickr },
  setup(_, { emit }) {
    const title = ref('');
    const isEventCreated = ref(false)
    const date = ref(new Date())
    const { locations } = useLocations();
    const locationIndex = ref(0);
    const { createEvent } = useEvents();


    const errorDate = ref(false);
    const { events } = useEvents();
    //console.log(events.value[0].date);

    const futureDates = events.value
        .map(event => event.date)
        //.includes(date.value);
    //const format = dateStr => formatDate(getDateFromStr(dateStr));

    //console.log(futureDates.includes(date.value.toISOString()));

    const create = async () => {
      //console.log(date.value.toISOString());
      //console.log(events.value[0].date);
      //console.log(futureDates.includes(date.value.toISOString()))
      const theSameDate = futureDates.includes(date.value.toISOString());
      //errorDate.value = true;
      if (theSameDate) {
        errorDate.value = true;
        setTimeout(()=>{
          errorDate.value = false;
        }, 5000);
      }
      //console.log(futureDates.includes(date.value))

      //console.log(errorDate.value);

      if (title.value && date.value && !theSameDate) {
        await createEvent({
          title: title.value,
          date: date.value.toISOString(),
          location: locations.value[locationIndex.value].key,
        })
        isEventCreated.value = true;
        errorDate.value = false;
      }
    };

    const close = () => {
      emit('close');
    }

    const onDateChange = ([d]) => {
      date.value = d
    }

    return {
      title,
      isEventCreated,
      date,
      locations,
      locationIndex,
      create,
      close,
      onDateChange,

      errorDate,
    };
  },
}
</script>

