<template>
  <modal title="Registered" @close="$emit('close')">
    <invite-info :seat="seat" :event="event" />
    <div v-if="seat && !seat.checkedIn && !seat.removed" class="mt-4 d-flex justify-content-center">
      <button class="btn btn-danger mr-2" @click="remove">Remove</button>
      <button class="btn btn-success ml-2" @click="checkIn">Check In</button>
    </div>
    <seat-add-photo
      :seat="seat"
      :location-key="event.location"
    />
  </modal>
</template>

<script>
import { computed } from 'vue';
import Modal from '../ui/Modal.vue'
import { useLocations, useInvites } from '@/store'
import InviteInfo from './InviteInfo.vue'
import SeatAddPhoto from './SeatAddPhoto.vue';

export default {
  name: 'RegisteredSeatModal',
  components: {
    InviteInfo,
    Modal,
    SeatAddPhoto,
  },
  props: {
    event: { type: Object, default: () => ({}) },
    seat: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const { getLocationByKey } = useLocations();
    const locationName = computed(() => getLocationByKey(props.event.location).name);
    const { invites, removeInvite, checkInInvite, getInviteByKey } = useInvites()
    const invite = computed(() => invites.value.length ? getInviteByKey(props.seat.inviteKey) : {});

    const remove = async () => {
      await removeInvite(invite.value)
      emit('close')
    }

    const checkIn = async () => {
      await checkInInvite(invite.value)
      emit('close')
    }

    return {
      locationName,
      remove,
      checkIn
    };
  },
}
</script>

<style scoped>

</style>