export const getDateFromStr = str => {
  // const dateArray = str.split('-').map(Number); // YYYY-MM-DD
  //console.log(str);
  return new Date(str);
};

export const formatDate = (date, weekday) => {
  const dateOptions = { weekday, year: 'numeric', month: 'numeric', day: 'numeric',hour:'numeric', minute:'numeric' };
  //const dateOptions = { weekday, year: 'numeric', month: 'numeric', day: 'numeric'};
  return new Intl.DateTimeFormat('en-Us', dateOptions).format(date);
};

export const formatTime = date => {
  const timeOptions = { hour: 'numeric', minute: 'numeric' };
  return new Intl.DateTimeFormat('en-Us', timeOptions).format(date);
};

export const formatDateTime = date => {
  const timeOptions = { hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Intl.DateTimeFormat('en-Us', timeOptions).format(date);
}

export const isDateInPast = date => {
  if (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};