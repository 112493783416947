import SignIn from '../components/auth/SignIn.vue';
// import SignUp from '../components/auth/SignUp.vue';
import ResetPassword from '../components/auth/ResetPassword.vue';
import EventRegister from '../components/hall/EventRegister.vue';
import Admin from '../components/admin/Admin.vue';
import Client from '../components/client/Client.vue'
import AdminCheckIn from "@/components/admin/AdminCheckIn.vue";
import Scanner from "@/components/admin/Scanner.vue";
import NotFound from '@/components/NotFound.vue';

export const routes = [
  {
    path: '/client',
    name: 'client',
    component: Client,
    beforeRouteEnter(to, from, next) {
      const { invite, link } = to.query;
      if (!invite && !link) {
        next({ name: 'home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignIn,
  },
  // {
  //   path: '/sign-up',
  //   name: 'sign-up',
  //   component: SignUp,
  // },
  {
    path: '/event-reg',
    name: '/event-reg',
    component: EventRegister,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/check-in',
    name: 'check-in',
    component: AdminCheckIn,
    beforeRouteEnter(to, from, next) {
      const { invite } = to.query;
      if (!invite) {
        next({ name: 'home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/scanner',
    name: 'scanner',
    component: Scanner,
  }
];
