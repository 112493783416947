<template>
  <modal>
    <div class="text-center" v-if="isCheckedIn">
      <check-icon active class="mx-auto" />
      <p><strong>Checked In</strong></p>
      <router-link class="btn btn-primary btn-block mt-4" to="/">Go to Admin</router-link>
    </div>
    <div class="text-center" v-else>
      <p>Please wait...</p>
    </div>
  </modal>
</template>

<script>
import { computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useInvites } from "@/store";
import Modal from "@/components/ui/Modal";
import CheckIcon from "@/components/ui/CheckIcon";

export default {
  name: "AdminCheckIn",
  components: {CheckIcon, Modal},
  setup() {
    const route = useRoute();
    const { invite: inviteId } = route.query;
    const { invites, getInviteByKey, checkInInvite } = useInvites();
    const invite = computed(() => invites.value.length ? getInviteByKey(inviteId) : {});
    const isCheckedIn = ref(false);
    const checkIn = async () => {
      if (invite.value) {
        await checkInInvite(invite.value);
        isCheckedIn.value = true;
      }
    };
    checkIn();
    watch(invite, () => {
      checkIn();
    });

    return {
      isCheckedIn,
    };
  },
}
</script>

<style scoped>

</style>