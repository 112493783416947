<template>
  <auth-layout title="Reset password" :error="error">
    <div class="form-group input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Email</span>
      </div>
      <input
        v-model="email"
        class="form-control"
        placeholder="example@mail.com"
        type="email"
        @input="clearError"
      >
    </div>
    <button class="btn btn-primary m-0 pl-4 pr-4" @click="sendEmail">Reset</button>
  </auth-layout>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import AuthLayout from './AuthLayout';
import { auth } from '../../lib/api';

export default {
  name: 'ResetPassword',
  components: {
    AuthLayout
  },
  setup() {
    const email = ref('');
    const error = ref('');
    const router = useRouter();

    const setError = message => {
      error.value = message
    };

    const clearError = () => setError('');

    const sendEmail = async () => {
      try {
        await auth.sendPasswordResetEmail(email.value);
        router.push({
          name: 'sign-in',
          query: { reset: true }
        });
      } catch ({ message }) {
         setError(message);
      }
    };

    return {
      sendEmail,
      clearError,
      error,
      email,
    }
  },
}
</script>
