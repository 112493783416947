import { ref, readonly } from 'vue';
import { LOCATIONS_PATH } from '@/lib/api';
import { useListCrud } from '@/lib/listCrud';
import {compareSeat, getSeatStr} from '@/lib/seat';

export const locationsSymbol = Symbol('locations');

export const createLocationsStore = async () => {
  const locations = ref([]);
  const { get, update } = await useListCrud(locations, LOCATIONS_PATH);

  const mapLocation = (location, callback) => location.seats.map(sector => sector.map(line => line.map(callback)));

  const getEventLocation = (event, invites) => {
    if (event.location && locations.value.length) {
      const location = get(event.location);
      return mapLocation(location, seat => {
        const seatInvite = invites.find(invite => invite.seat && compareSeat(invite.seat, seat));
        const newSeat = seatInvite && !seatInvite.removed
          ? {
            ...seat,
            ...seatInvite.seat,
            confirmed: seatInvite.confirmed,
            registerBy: seatInvite.name,
            registerByEmail: seatInvite.email,
            checkedIn: seatInvite.checkedIn,
            inviteTime: seatInvite.time,
            inviteKey: seatInvite.key,
          }
          : seat;
        const locked = Boolean(event.blockOff) && event.blockOff.includes(getSeatStr(seat));
        return { ...newSeat, locked  }
      });
    }
    return [];
  };

  const addPhotoUrlToSeat = async (url, seat, locationKey) => {
    const location = get(locationKey);
    await update(
      locationKey,
      {
        name: location.name,
        seats: mapLocation(location, s => {
          return compareSeat(seat, s) ? {...s, photoUrl: url} : s;
        }),
      }
    );
  };

  return {
    locations: readonly(locations),
    getLocationByKey: get,
    getEventLocation,
    addPhotoUrlToSeat,
  };
}
