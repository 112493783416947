import { database } from "@/lib/api";

export const useListCrud = async (list, dbRefKey) => {
  const dbRef = database.ref(dbRefKey);
  const addItem = (key, data) => {
    if (list.value.every(it => it.key !== key)) {
      list.value.unshift({ ...data, key });
    }
  };
  const changeItem = (key, data) => {
    const eventIndex = list.value.findIndex(it => it.key === key);
    if (eventIndex !== -1) {
      list.value = list.value.map((it, index) => index === eventIndex ? { ...data, key } : it);
    }
  };
  const removeItem = key => {
    const eventIndex = list.value.findIndex(it => it.key === key);
    if (eventIndex) {
      list.value.splice(eventIndex, 1);
    }
  };

  const listSnapshot = await dbRef.once('value');
  listSnapshot.forEach(childSnapshot => addItem(childSnapshot.key, childSnapshot.val()));

  dbRef.on('child_added', data => addItem(data.key, data.val()));
  dbRef.on('child_changed', data => changeItem(data.key, data.val()));
  dbRef.on('child_removed', data => removeItem(data.key));

  const get = key => list.value.find(item => item.key === key) || null;
  const update = async (key, data) => {
    const updData = { ...data };
    delete updData.key;
    await database.ref(`${dbRefKey}/${key}`).set(updData);
  };
  const create = async data => {
    const push = dbRef.push();
    await push.set(data);
    return {
      ...data,
      key: push.key,
    };
  }
  const remove = async key => {
    await database.ref(`${dbRefKey}/${key}`).remove()
  }


  return {
    get,
    update,
    create,
    remove,
  }
};