<template>
  <div class="panorama"></div>
</template>

<script>
import PanoLens from '../../lib/panolens';

export default {
  name: 'Panolens',
  props: {
    source: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      size: {
        width: this.width,
        height: this.height
      },
      viewer: null,
      panorama: null
    }
  },
  created() {
    window.addEventListener('resize', this.onResize, false)
  },
  // unmounted() {
  //   if (this.panorama && this.panorama.dispose) {
  //     this.panorama.dispose()
  //   }
  // },
  mounted () {
    if (this.width === undefined || this.height === undefined) {
      this.size = {
        width: this.$el.offsetWidth,
        height: this.$el.offsetHeight
      }
    }
    this.viewer = new PanoLens.Viewer({
      container: this.$el,
      cameraFov: 100,
      controlButtons: ['fullscreen']
    })
    this.loadPano()
  },
  watch: {
    source() {
      if (!this.viewer) return
      this.loadPano()
    }
  },
  methods: {
    onResize() {
      if (this.width === undefined || this.height === undefined) {
        this.$nextTick(() => {
          this.size = {
            width: this.$el.offsetWidth,
            height: this.$el.offsetHeight
          }
        })
      }
    },
    loadPano() {
      if (!this.source) return
      const pano = new PanoLens.ImagePanorama(this.source)
      this.setPano(pano)
    },
    setPano(pano) {
      if (!pano) return
      if (this.panorama) {
        this.viewer.remove(this.panorama)
      }
      this.panorama = pano
      this.viewer.add(this.panorama)
      this.viewer.setPanorama(this.panorama)
      const that = this
      this.panorama.addEventListener('load', () => {
        that.$emit('on-load')
      })
    }
  }
}
</script>

<style scoped>
.panorama {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}
</style>
