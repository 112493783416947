<template>
  <nav class="navbar navbar-dark navbar-expand-lg navbar-light mb-4" style="background-color: #242424">
    <div class="logo navbar-brand mr-auto ml-2"></div>
    <button
        v-if="$props.showNav"
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown" v-if="$props.showNav">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" v-if="$props.showSendInvite">
          <a class="btn btn-outline-primary mr-4 mt-2" @click="$emit('open', 'send-invite')">
            Send Invite
          </a>
        </li>
        <li class="nav-item">
          <a class="btn btn-outline-primary mr-4 mt-2" @click="$emit('open', 'create-event')">
            Create Event
          </a>
        </li>
        <li class="nav-item">
          <router-link class="btn btn-outline-primary mr-4 mt-2" to="/scanner">Check In</router-link>
        </li>
        <li class="nav-item">
          <a class="btn btn-outline-primary mt-2" @click="$emit('open', 'exit')">Exit</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    showNav: { type: Boolean, default: false },
    showSendInvite: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.logo {
  height: 90px;
  width: 170px;
  background-image: url(../assets/fslogo.png);
  background-size: cover;
}

.btn {
  min-width: 120px;
}
</style>