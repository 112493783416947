<template>
  <div class="text-center" v-if="event">
    <p class="m-0" style="color: #949494">{{ eventDate }}</p>
    <p class="m-0 h4"><strong>{{ event.title }}</strong></p>
    <p class="m-0" style="color: #949494">{{ locationName }}</p>
    <p v-if="seat && seat.seat && seat.line" class="m-0" style="color: #949494">Seat {{ seat.seat }}{{ seat.line }}</p>
    <p v-else class="m-0" style="color: #949494">pending</p>
    <p v-if="seat && seat.registerBy" class="m-0 h4"><strong>{{ seat.registerBy }}</strong></p>
    <p v-if="seat && seat.registerByEmail" class="m-0" style="color: #949494">{{ seat.registerByEmail }}</p>
    <p v-if="seat && seat.inviteTime" class="m-0" style="color: #949494">
      The invitation was sent - {{ formatDateTime(new Date(seat.inviteTime)) }}
    </p>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useLocations } from '@/store';
import { getDateFromStr, formatDateTime } from '@/lib/date';

export default {
  name: "InviteInfo",
  props: {
    event: { type: Object, default: () => ({}) },
    seat: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const { getLocationByKey } = useLocations();
    const locationName = computed(() => {
      if (props.event) {
        const location = getLocationByKey(props.event.location);
        return location && location.name;
      }
      return '';
    });

    const eventDate = computed(() => {
      if (props.event) {
        return formatDateTime(getDateFromStr(props.event.date))
      }
      return '';
    });

    return {
      locationName,
      eventDate,
      formatDateTime,
    };
  },
}
</script>
