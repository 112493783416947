import { ref, readonly } from 'vue';
import { LINKS_PATH } from "@/lib/api";
import { useListCrud } from "@/lib/listCrud";

export const linksSymbol = Symbol('links');

export const createLinksStore = async () => {
    const links = ref([]);
    const { get, create, remove } = await useListCrud(links, LINKS_PATH);

    return {
        links: readonly(links),
        getLink: get,
        createLink: create,
        removeLink: remove,
    };
}
