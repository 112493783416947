import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

//const STORAGE_BUCKET = 'seat-app-19e9d.appspot.com';

const firebaseConfig = {
  apiKey: 'AIzaSyDCKki4DBCDrWnY3sasYet9ZmDnJbCW1os',
  authDomain: 'seat-app-19e9d.firebaseapp.com',
  databaseURL: 'https://seat-app-19e9d.firebaseio.com',
  projectId: 'seat-app-19e9d',
  storageBucket: 'seat-app-19e9d.appspot.com',
  messagingSenderId: '792742277600',
  appId: '1:792742277600:web:8cd1ec65f2e4029ef6bb85',
  measurementId: 'G-80GB7QTVCT'
};


//DEV
/*const firebaseConfig = {
  apiKey: "AIzaSyDA6bSNbgnlKay3kuUydGWmiY00tcQgWi0",
  authDomain: "seat-app-dev.firebaseapp.com",
  databaseURL: "https://seat-app-dev.firebaseio.com",
  projectId: "seat-app-dev",
  storageBucket: "seat-app-dev.appspot.com",
  messagingSenderId: "716841490392",
  appId: "1:716841490392:web:08c1fbc5c18d91450ac4ad",
  measurementId: "G-79V362EH91"
};*/

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
//console.log(auth)
export const database = firebase.database();
export const EVENTS_PATH = 'events';
export const INVITES_PATH = 'invites';
export const LOCATIONS_PATH = 'locations';
export const LINKS_PATH = 'links';

export const fileStorage = firebase.storage();
export const IMAGES_PATH = 'images';
