<template>
  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Seat</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Date Sent</th>
        <th scope="col">Confirmed</th>
        <th scope="col">Checked In</th>
      </tr>
    </thead>
    <tbody>
      <tr
        style="cursor: pointer"
        v-for="invite in invites"
        :key="invite.id"
        @click="inviteClicked(invite)"
      >
        <td>
          <span v-if="invite.seat">{{ invite.seat.seat }}{{ invite.seat.line }}</span>
          <span v-else-if="!invite.removed">Pending</span>
          <span v-else>Removed</span>
        </td>
        <td>{{ invite.name }}</td>
        <td>{{ invite.email }}</td>
        <td>{{ invite.time ? formatDateTime(new Date(invite.time)) : '' }}</td>
        <td>
          <check-icon :active="invite.confirmed" :size="28" />
        </td>
        <td>
          <check-icon :active="invite.checkedIn" :size="28" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed } from 'vue';

import CheckIcon from '../ui/CheckIcon';
import { useInvites } from '@/store';
import { formatDateTime } from '@/lib/date';

export default {
  name: 'OrdersTable',
  components: {
    CheckIcon
  },
  emits: ['click-invite'],
  props: {
    event: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const { invitesByEvent, confirmInvite } = useInvites();
    const invites = computed(() => invitesByEvent(props.event));

    const inviteClicked = invite => {
      emit('click-invite', invite)
    }

    return {
      confirmInvite,
      invites,
      formatDateTime,
      inviteClicked,
    };
  },
};
</script>

<style scoped>
/*.invites-row_unconfirmed {*/
/*  cursor: pointer;*/
/*  background: rgba(255, 0, 0, .5);*/
/*}*/
td, th {
  background: #373A3C!important;
  color: #fff;
}
@media screen and (max-width: 500px) {
  th {
    padding: .5rem;
    font-size: 10px;
  }
  td {
    padding: .5rem;
    font-size: 10px;
  }
}

</style>