<template>
  <modal title="Add user" @close="$emit('close')">
    <template v-if="isShowModal">
      <invite-info  :seat="seat" :event="event" />
      <form @submit.prevent="sendInvite">
        <div class="form-group">
          <label for="nameInput">Name</label>
          <input
              v-model="name"
              type="text"
              class="form-control"
              id="nameInput"
              placeholder="input your name"
          >
        </div>
        <div class="form-group">
          <label for="emailInput">Email</label>
          <input
              v-model="email"
              type="email"
              class="form-control"
              id="emailInput"
              placeholder="input your email"
          >
        </div>
        <button type="submit" class="btn btn-block btn-primary mt-4" :disabled="!isValid">Send Invite</button>
        <button class="btn btn-block btn-danger" @click="addBlockOff" v-if="seat">Block Off</button>
      </form>
    </template>
    <seat-add-photo
      v-if="seat"
      :seat="seat"
      :location-key="event.location"
      @start-edit="closeModal"
      @stop-edit="openModal"
      @add-photo="$emit('add-photo', $event)"
    />
  </modal>
</template>

<script>
import { ref, computed } from 'vue';
import Modal from '@/components/ui/Modal';
import {useEvents, useInvites} from "@/store";
import InviteInfo from "@/components/admin/InviteInfo";
import SeatAddPhoto from "@/components/admin/SeatAddPhoto";
import { useModal } from "@/lib/modalControl";
import { sendInviteEmail } from "@/lib/emails";

export default {
  name: 'SendInviteModal',
  components: { SeatAddPhoto, InviteInfo, Modal },
  props: {
    event: { type: Object, default: () => ({}) },
    seat: { type: Object, default: null },
  },
  setup(props, { emit }) {
    const name = ref('');
    const email = ref('');

    const isValid = computed(() => name.value && email.value);
    const { createInvite, invites } = useInvites();
    const sendInvite = async () => {
      const lastInvite = invites.value[invites.value.length - 1];
      if (isValid.value) {
        try {
          const invite = await createInvite({
            name: name.value,
            email: email.value,
            event: props.event.key,
            seat: props.seat,
            confirmed: false,
            checkedIn: false,
            number: lastInvite ? lastInvite.number + 1 : 1,
            time: new Date().toISOString()
          });
          await sendInviteEmail(invite, props.event, email.value, name.value);
          emit('close');
        } catch (e) {
          console.error(e);
        }
      }
    };

    const { isShowModal, openModal, closeModal } = useModal(true);

    const { addBlockOffSeat } = useEvents();
    const addBlockOff = async () => {
      await addBlockOffSeat(props.seat, props.event);
      emit('close');
    }

    return {
      name,
      email,
      isValid,
      sendInvite,
      isShowModal,
      openModal,
      closeModal,
      addBlockOff,
    };
  },
}
</script>

<style scoped>

</style>