<template>
  <div
    class="check-icon"
    :class="{ 'check-icon_active': $props.active }"
    :style="{
      '--height': `${$props.size}px`,
      '--width': `${$props.size}px`
    }"
  />
</template>

<script>
export default {
  name: 'CheckIcon',
  props: {
    active: { type: Boolean, default: false },
    size: { type: Number, default: 45 },
  }
}
</script>

<style scoped>
  .check-icon {
    background-image: url(../../assets/confirm.svg);
    background-color: #373A3C;
    border-radius: 50%;
    background-position: center;
    height: var(--height);
    width: var(--width);
  }
  .check-icon_active {
    background-color: #51e91a;
  }
  @media screen and (max-width: 400px) {
    .check-icon {
      height: calc(var(--height) / 1.25);
      width: calc(var(--width) / 1.25);
    }
  }
</style>