<template>
  <modal title="Check In" @close="$emit('close')" class="qr-modal">
    <div ref="codeEl" class="code mx-auto"></div>
  </modal>
</template>

<script>
import { onMounted, ref } from 'vue';
import Modal from "@/components/ui/Modal";
import QRCode from "@/lib/qrcode";

export default {
  name: "QrCode",
  components: {
    Modal,
  },
  props: {
    inviteKey: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const codeEl = ref();
    onMounted(() => {
      codeEl.value.innerHTML = '';
      const qrCode = new QRCode(codeEl.value, {
        text: props.inviteKey,
        width: 300,
        height: 300,
        colorDark : "#000000",
        colorLight : "#ffffff",
        correctLevel : QRCode.CorrectLevel.M
      });
      emit('qr-code-save', qrCode._oDrawing._elCanvas);
    });

    return {
      codeEl
    };
  },
}
</script>

<style>
  .qr-modal .modal-body {
    background: white;
  }
</style>