import Instascan from 'instascan';

Instascan.Scanner.prototype._enableScan = async function(camera) {
  this._camera = camera || this._camera;
  if (!this._camera) {
    throw new Error('Camera is not defined.');
  }

  let streamUrlObj = await this._camera.start();
  this.video.srcObject = streamUrlObj;

  if (this._continuous) {
    this._scanner.start();
  }
}

Instascan.Camera.prototype.start = async function () {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const constraints = {
    audio: false,
    video: {
      facingMode: iOS ? 'environment' : undefined,
      mandatory: {
        sourceId: this.id,
        minWidth: 600,
        maxWidth: 800,
        minAspectRatio: 1.6
      },
      optional: []
    }
  };

  this._stream = await navigator.mediaDevices.getUserMedia(constraints);
  return this._stream;
}

export default Instascan;