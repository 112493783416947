<template>
  <div class="d-flex justify-content-end">
    <div @click="onOpenModal" class="btn btn-link text-end px-0 mx-0 mt-2">
      <div :class="seat.photoUrl ? 'photo-icon' : 'add-photo-icon'"></div>
    </div>
    <modal v-if="isShowModal" title="Photo" @close="closePhotoAdding">
      <panorama v-if="newFileUrl || seat.photoUrl" :source="newFileUrl || seat.photoUrl" />
      <div class="text-center col-md-6 mx-auto">
        <button class="btn btn-primary mb-2" @click="$refs.fileInput.click()" v-if="!isLoading">Update image</button>
        <div class="spinner-border text-primary mb-2" role="status" v-else>
          <span class="sr-only">Loading...</span>
        </div>
        <p class="m-0 h4"><strong>{{ locationName }}</strong></p>
        <p v-if="seat" class="m-0" style="color: #949494">Seat {{ seat.seat }}{{ seat.line }}</p>
        <button v-if="newFileUrl" class="btn btn-success mt-2" @click="applyPhotoAdding">Apply</button>
      </div>
      <input
        ref="fileInput"
        type="file"
        class="form-control-file m-0 p-0"
        accept=".png, .jpg, .jpeg"
        style="visibility: hidden; position: absolute;"
        @change="onFileInput"
      >
    </modal>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import Panorama from '../ui/Panorama.vue';
import Modal from '../ui/Modal.vue';
import { useModal } from "@/lib/modalControl";
import { useLocations } from "@/store";
import { fileStorage, IMAGES_PATH } from '@/lib/api';
import { getSeatStr } from '@/lib/seat';

export default {
  name: 'SeatAddPhoto',
  components: { Modal, Panorama },
  props: {
    seat: { type: Object, default: () => ({}) },
    locationKey: { type: String, default: ' ' },
  },
  setup(props, { emit }) {
    const newFileUrl = ref('');
    const isLoading = ref(false);

    const { isShowModal, closeModal, openModal } = useModal();
    const onOpenModal = () => {
      openModal();
      emit('start-edit');
    };
    const onCloseModal = () => {
      closeModal();
      newFileUrl.value = '';
      emit('stop-edit');
    };

    const { getLocationByKey, addPhotoUrlToSeat } = useLocations();
    const location = computed(() => getLocationByKey(props.locationKey));
    const locationName = computed(() => location.value ? location.value.name : '');

    const fileInput = ref();
    const path = computed(() => `${IMAGES_PATH}/${props.locationKey}-${getSeatStr(props.seat)}`);
    const onFileInput = async () => {
      try {
        isLoading.value = true;
        const [file] = fileInput.value.files;
        if (file) {
          const imgRef = fileStorage.ref(path.value)
          await imgRef.put(file);
          newFileUrl.value = await imgRef.getDownloadURL()
        }
      } finally {
        isLoading.value = false;
      }
    };

    const closePhotoAdding = async () => {
      if (newFileUrl.value) {
        await fileStorage.ref(path.value).delete();
      }
      onCloseModal();
    };
    const applyPhotoAdding = async () => {
      await addPhotoUrlToSeat(
        newFileUrl.value,
        props.seat,
        props.locationKey
      );
      emit('add-photo', newFileUrl.value)
      onCloseModal();
    };

    return {
      fileInput,
      isShowModal,
      onCloseModal,
      onOpenModal,
      onFileInput,
      locationName,
      newFileUrl,
      closePhotoAdding,
      applyPhotoAdding,
      isLoading,
    }
  },
}
</script>

<style scoped>
.photo-icon {
  height: 28px;
  width: 28px;
  background-image: url(../../assets/photo.svg);
  background-size: 28px 28px;
}

.add-photo-icon {
  height: 28px;
  width: 28px;
  background-image: url(../../assets/add-photo.svg);
  background-size: 28px 28px;
}

</style>