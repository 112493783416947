import { getDateFromStr, formatDate } from "@/lib/date";

// const EMAIL_URL = 'https://api.maropost.com/accounts/2137/emails/deliver.json';
// const AUTH_TOKEN = '_UgVzhXyHmIuPSremPef6IqMo00eDTLYvD3taK8lnQRu4RSw1yeM7w';

const CAMPAIGN = {
  SEAT_CONFIRMATION: 3084,
  RESERVE_SEAT_NOTIFICATION: 3086,
  CANCEL: 3198
};

const sendEmail = async config => {
  await fetch('../test.php',{
    method: 'post',
    body: JSON.stringify({
      ...config,
      reply_to: 'test@maropost.com',
    }),
  });
};

export const sendInviteEmail = (invite, event, email, name) => {
  const date = formatDate(getDateFromStr(event.date));
  return sendEmail({
    campaign_id: CAMPAIGN.RESERVE_SEAT_NOTIFICATION,
    subject: '[SeatApp]: Notification to reserve a seat',
    email,
    first_name: name,
    EventTitle: event.title,
    DateTime: date,
    conformation_link: `${window.location.host}/client?invite=${invite.key}`,
  });
};

export const sendSeatConfirmation = (invite, event) => {
  const date = formatDate(getDateFromStr(event.date));
  return sendEmail({
    campaign_id: CAMPAIGN.SEAT_CONFIRMATION,
    subject: '[SeatApp]: Seat Confirmation',
    email: invite.email,
    first_name: invite.name,
    EventTitle: event.title,
    DateTime: date,
    ReservationNumber: `#${invite.number}/${event.date.replace(/-/g, '')}`,
    conformation_link: `${window.location.host}/client?invite=${invite.key}`,
    qr: invite.qrCodeLink,
  });
};

export const cancellationEventMailing = (invites, event, reason) => {
  const date = formatDate(getDateFromStr(event.date));
  invites.forEach(invite => {
    sendEmail({
      campaign_id: CAMPAIGN.CANCEL,
      subject: '[SeatApp]: Event Cancellation',
      EventTitle: event.title,
      email: invite.email,
      first_name: invite.name,
      DateTime: date,
      ReservationNumber: `#${invite.number}/${event.date.replace(/-/g, '')}`,
      reason
    });
  });
};