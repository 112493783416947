<template>
  <div>
    <modal v-if="!invite || invite.removed || !event || event.canceled" class="text-center">
      Something went wrong, please contact us 8-800-123123
    </modal>
<!--    <navigation v-if="event && invite && !invite.removed && !event.canceled" />-->
    <div class="background-hall"></div>
    <div class="col-md-8 mx-auto hall-container" v-if="invite && !invite.confirmed && !invite.removed  && !event.canceled">
      <h2 style="color: #fff">{{ event.title }}</h2>
      <h3 style="color: #fff">{{ formatDateTime(getDateFromStr(event.date)) }}</h3>
      <hall v-if="event" class="hall mb-4 py-4" @select-seat="selectSeat" :event="event" />
    </div>
    <confirm-seat-modal
      v-if="showModals.confirm && invite && !invite.removed && !event.canceled"
      :invite="invite"
      :event="event"
      :seat="inviteSeat"
      @close="closeConfirm"
      @confirm="onConfirmed"
    />
    <check-in-modal
      v-if="showModals.checkIn && invite && !invite.removed && !event.canceled"
      :invite="invite"
      @close="showModals.checkIn = false"
      @check-in="onCheckedIn"
    />
    <qr-code
      v-show="showModals.qr && invite && !invite.removed && !event.canceled"
      :invite-key="invite && invite.key"
      @close="showModals.qr = false; showModals.checkIn = true"
      @qr-code-save="saveQrCode"
    />
    <EventLinkModal
      v-if="showModals.eventLink"
      :event="event"
      :link="link"
    />
  </div>
</template>

<script>
import { computed, ref, reactive, onMounted } from 'vue';
import Hall from '../hall/Hall';
// import Navigation from '../Navigation.vue';
import { useEvents, useInvites, useLinks } from '@/store';
import {useRoute, useRouter} from 'vue-router';
import CheckInModal from './CheckInModal.vue';
import ConfirmSeatModal from "@/components/client/ConfirmSeatModal";
import QrCode from "@/components/client/QrCode";
import {fileStorage} from "@/lib/api";
import {sendSeatConfirmation} from "@/lib/emails";
import Modal from '../ui/Modal.vue';
import EventLinkModal from "@/components/client/EventLinkModal";
import { getDateFromStr, formatDateTime } from '@/lib/date';

export default {
  name: 'Client',
  components: {
    EventLinkModal,
    QrCode,
    ConfirmSeatModal,
    CheckInModal,
    // Navigation,
    Hall,
    Modal,
  },
  setup() {
    const showModals = reactive({
      confirm: false,
      checkIn: false,
      qr: false,
      eventLink: false,
    })

    const route = useRoute();
    const { invite: inviteId, link: linkId } = route.query;

    const { getInviteByKey, addQrCodeLink } = useInvites();
    const invite = computed(() => getInviteByKey(inviteId));

    const { getLink } = useLinks()
    const link = computed(() => getLink(linkId))

    const { getEventByKey } = useEvents();
    const event = computed(() => {
      if (invite.value) {
        return getEventByKey(invite.value.event)
      } else if (link.value) {
        return getEventByKey(link.value.event)
      }
      return null
    });

    const router = useRouter();
    onMounted(() => {
      if (invite.value === null && link.value === null) {
        router.push({ name: 'not-found' });
      } else if (invite.value !== null && !invite.value.removed && !event.value.canceled) {
        if (invite.value.confirmed) {
          showModals.checkIn = true;
        } else if (!invite.value.confirmed && invite.value.seat) {
          showModals.confirm = true;
        }
      } else if (link.value) {
        showModals.eventLink = true
      }
    });

    const selectedSeat = ref(null);
    const closeConfirm = () => {
      showModals.confirm = false;
      selectedSeat.value = null;
    };
    const selectSeat = seat => {
      selectedSeat.value = seat;
      if (!seat.confirmed && !seat.registerBy && !seat.locked) {
        showModals.confirm = true;
      }
    };

    const inviteSeat = computed(() => selectedSeat.value || invite.value.seat);

    const onConfirmed = () => {
      showModals.confirm = false;
      showModals.checkIn = true;
    };

    const onCheckedIn = () => {
      showModals.checkIn = false;
      showModals.qr = true;
    };

    const saveQrCode = canvas => {
      if (invite.value && !invite.value.qrCodeLink && !invite.value.removed && !event.value.canceled) {
        canvas.toBlob(async blob => {
          if (blob) {
            const imgRef = fileStorage.ref(`qrcodes/${invite.value.key}`);
            await imgRef.put(blob);
            const qrCodeLink = await imgRef.getDownloadURL();
            addQrCodeLink(invite.value, qrCodeLink);
            await sendSeatConfirmation({ ...invite.value, qrCodeLink }, event.value);
          }
        });
      }
    };

    return {
      event,
      inviteSeat,
      invite,
      link,
      selectSeat,
      showModals,
      closeConfirm,
      onConfirmed,
      onCheckedIn,
      saveQrCode,
      getDateFromStr,
      formatDateTime,
    };
  }
};
</script>

<style scoped>
.hall {
  border: 2px #343a40 solid;
}
.hall-container {
  margin-top: 40vh;
}
.background-hall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  background-image: url(../../assets/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>