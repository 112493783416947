<template>
  <div>
    <video ref="video" />
    <div v-if="isCameraNotFound" class="text-center">
      <div class="h1">Camera not found</div><br>
      <router-link to="/" class="h3">Back to Admin</router-link>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Instascan from '@/lib/scanner';

export default {
  name: 'Scanner',
  setup() {
    const video = ref();
    const isCameraNotFound = ref(false);
    const showError = () => {
      isCameraNotFound.value = true;
    };
    const qrScanner = ref(null);
    const router = useRouter();
    onMounted(async () => {
      qrScanner.value = new Instascan.Scanner({
        video: video.value,
        mirror: false,
      });
      qrScanner.value.addListener('scan', decoded => {
        router.push({ name: 'check-in', query: { invite: decoded } });
      });
      try {
        const cameras = await Instascan.Camera.getCameras();
        if (cameras.length > 0) {
          const backCamera = cameras.find(camera => camera.name && camera.name.match(/back/i));
          const camera = backCamera || cameras[0];
          await qrScanner.value.start(camera);
        } else {
          showError();
        }
      } catch (e) {
        showError();
      }
    });
    onUnmounted(() => {
      qrScanner.value.stop();
    });

    return {
      video,
      isCameraNotFound,
    };
  }
}
</script>
