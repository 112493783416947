<template>
  <modal title="Register for the event"  @close="$emit('close')" v-if="event">
    <invite-info :event="event" />
    <form @submit.prevent="sendInvite">
      <div class="form-group">
        <label for="nameInput">Name</label>
        <input
            v-model="name"
            type="text"
            class="form-control"
            id="nameInput"
            placeholder="input your name"
        >
      </div>
      <div class="form-group">
        <label for="emailInput">Email</label>
        <input
            v-model="email"
            type="email"
            class="form-control"
            id="emailInput"
            placeholder="input your email"
        >
      </div>
      <button type="submit" class="btn btn-block btn-primary mt-4" :disabled="!isValid">Register</button>
    </form>
  </modal>
</template>

<script>
import Modal from '@/components/ui/Modal'
import {computed, ref} from "vue";
import {useInvites, /*useLinks*/} from "@/store";

export default {
  name: 'EventLinkModal',
  components: { Modal },
  props: {
    link: {
      type: Object,
      default: () => ({})
    },
    event: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props) {
    const name = ref('');
    const email = ref('');

    const isValid = computed(() => name.value && email.value);
    const { createInvite, invites } = useInvites();

    // const { removeLink } = useLinks()

    const sendInvite = async () => {
      const lastInvite = invites.value[invites.value.length - 1];
      if (isValid.value) {
        try {
          // await removeLink(props.link.key)
          const invite = await createInvite({
            name: name.value,
            email: email.value,
            event: props.event.key,
            seat: null,
            confirmed: false,
            checkedIn: false,
            number: lastInvite ? lastInvite.number + 1 : 1,
            time: new Date().toISOString()
          });
          window.location.href = `http://${window.location.host}/client?invite=${invite.key}`
        } catch (e) {
          console.error(e);
        }
      }
    };

    return {
      name,
      email,
      isValid,
      sendInvite,
    }
  }
}
</script>

<style scoped>

</style>