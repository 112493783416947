<template>
  <modal title="Event registration form">
    <form @submit.prevent="register">
      <div class="form-group input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Name</span>
        </div>
        <input v-model="name" class="form-control" placeholder="John Smith" type="text">
      </div>
      <div class="form-group input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Company</span>
        </div>
        <input v-model="company" class="form-control" placeholder="Example, inc" type="text">
      </div>
      <div class="form-group input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Email</span>
        </div>
        <input v-model="email" class="form-control" placeholder="example@mail.com" type="email">
      </div>
      <div class="form-group input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Phone</span>
        </div>
        <input v-model="phoneNumber" class="form-control" placeholder="xxx-xxx-xxxx" type="text">
      </div>
      <div class="form-group input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Subject</span>
        </div>
        <select v-model="subject" class="custom-select">
          <option value="-1">Select one...</option>
          <option value="0">First choice</option>
          <option value="1">Second choice</option>
          <option value="2">Third choice</option>
        </select>
      </div>
      <button type="submit" class="btn btn-primary m-0 pl-4 pr-4">Submit</button>
    </form>
  </modal>
</template>

<script>
import { ref } from 'vue';
import Modal from '../ui/Modal';
import { useStore } from '@/store';
import {database, REQUESTS_PATH} from '../../lib/api';

export default {
  name: 'EventRegister',
  components: { Modal },
  props: {
    places: { type: Array, default: () => [] },
  },
  setup(props) {
    const name = ref('');
    const company = ref('');
    const { userEmail } = useStore();
    const email = ref(userEmail);
    const phoneAreaCode = ref('');
    const phoneNumber = ref('');
    const subject = ref('-1');
    const isCustomer = ref('');

    const register = () => {
      const data = {
        name: name.value,
        company: company.value,
        email: email.value,
        phone: `${phoneAreaCode.value}${phoneNumber.value}`,
        subject: subject.value,
        isCustomer: Boolean(isCustomer.value),
        places: props.places,
        confirm: false,
        checkIn: false,
      };
      database.ref(REQUESTS_PATH).push().set(data);
    };

    return {
      name,
      company,
      email,
      phoneAreaCode,
      phoneNumber,
      subject,
      isCustomer,
      register,
    };
  },
}
</script>
