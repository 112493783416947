import { inject } from 'vue';
import { invitesSymbol, createInvitesStore } from './invites';
import { eventsSymbol, createEventsStore } from './events';
import { locationsSymbol, createLocationsStore } from './locations';
import { linksSymbol, createLinksStore } from './links';

export const provideModules = async app => {
  app.provide(invitesSymbol, await createInvitesStore());
  app.provide(eventsSymbol, await createEventsStore());
  app.provide(locationsSymbol, await createLocationsStore());
  app.provide(linksSymbol, await createLinksStore());
};

export const useInvites = () => inject(invitesSymbol);
export const useEvents = () => inject(eventsSymbol);
export const useLocations = () => inject(locationsSymbol);
export const useLinks = () => inject(linksSymbol);
