import { createWebHistory, createRouter } from 'vue-router';
import { routes } from './routes';
import { auth } from '../lib/api';


const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve((to, from, next) => {
  const customerRoutes = ['sign-in', 'client', 'not-found'];
  if (auth.currentUser || customerRoutes.includes(to.name)) {
    next();
  } else {
    next({ name: 'sign-in' });
  }
});

export default router;