<template>
  <modal title="Сancellation event" @close="$emit('close')">
    <h5>Do you really want to cancel event?</h5>
    <input
      v-model="reason"
      class="form-control mt-3 mb-3"
      placeholder="Enter the reason for cancellation"
      type="text"
    >
    <button @click="confirmCancellation" class="btn btn-danger btn-block">Cancel event</button>
  </modal>
</template>

<script>
import { ref } from 'vue';
import Modal from "@/components/ui/Modal";
import {useEvents, useInvites} from "@/store";
import {cancellationEventMailing} from "@/lib/emails";

export default {
  name: 'CancelEventModal',
  components: {Modal},
  props: {
    event: { type: Object, default: null },
  },
  setup(props, { emit }) {
    const reason = ref('');

    const { cancelEvent } = useEvents();
    const { invitesByEvent } = useInvites();
    const confirmCancellation = () => {
      cancelEvent(props.event, reason.value);
      cancellationEventMailing(invitesByEvent(props.event), props.event, reason.value);
      emit('close');
    };

    return {
      reason,
      confirmCancellation
    };
  }
}
</script>

<style scoped>

</style>