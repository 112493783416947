<template>
  <modal title="BLOCKED" @close="$emit('close')">
    <invite-info :seat="seat" :event="event" />
    <p class="m-0 h4 text-center"><strong>By Admin</strong></p>
    <button class="btn btn-success btn-block mt-4" @click="openSeat">Open Seat</button>
    <seat-add-photo
      :seat="seat"
      :location-key="event.location"
    />
  </modal>
</template>

<script>
import InviteInfo from "@/components/admin/InviteInfo";
import Modal from "@/components/ui/Modal";
import SeatAddPhoto from './SeatAddPhoto.vue';
import {useEvents} from "@/store";

export default {
  name: 'LockedSeatModal',
  components: {
    Modal,
    InviteInfo,
    SeatAddPhoto,
  },
  props: {
    event: { type: Object, default: () => ({}) },
    seat: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const { removeBlockOffSeat } = useEvents()
    const openSeat = async () => {
      await removeBlockOffSeat(props.seat, props.event);
      emit('close');
    }

    return {
      openSeat,
    };
  },
}
</script>

<style scoped>

</style>