<template>
  <div
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    style="display: block; overflow: visible"
  >
    <div class="modal-dialog" role="document" style="border: 1px solid #ECEEEF; top: 45%; transform: translateY(-50%)">
      <div class="modal-content" style="background-color: #242424; color: #fff; border: none">
        <div
          v-if="title"
          class="modal-header"
        >
          <h5 class="modal-title" style="position: absolute; left: 50%;transform: translateX(-50%);">{{ $props.title }}</h5>
          <button
            type="button"
            class="close"
            style="color: #fff; box-shadow: none"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-if="$slots.default">
          <slot></slot>
        </div>
        <div v-if="$slots.footer" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: { type: String, default: '' },
  },
}
</script>
