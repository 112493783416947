<template>
  <div class="hall" ref="hallEl">
    <div class="hall-container">
      <div class="hall-block pb-4 px-2">
        <div class="hall-stage">
          STAGE
        </div>
        <div class="seats">
          <div
            v-for="(sector, index) in sectors"
            :key="index"
            :class="{
              'seats__out-sector': index === sectors.length - 1 || index === 0
            }"
          >
            <sector
              :seats="sector"
              :show-line-left="index !== sectors.length - 1 || sectors.length === 1"
              :admin="admin"
              @select-seat="selectSeat"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, watch, onMounted } from 'vue';
import Sector from './Sector.vue';
import { useInvites, useLocations} from '@/store';

export default {
  name: 'Hall',
  components: { Sector },
  props: {
    admin: { type: Boolean, default: false },
    event: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const { invitesByEvent } = useInvites()
    const { getEventLocation } = useLocations();
    const sectors = ref(getEventLocation(props.event, invitesByEvent(props.event)));
    watchEffect(() => {
      sectors.value = getEventLocation(props.event, invitesByEvent(props.event));
    })

    const hallEl = ref();
    const scrollToCenter = () => {
      if (hallEl.value) {
        hallEl.value.scrollLeft = (hallEl.value.scrollWidth - hallEl.value.clientWidth) / 2;
      }
    };
    onMounted(() => {
      scrollToCenter();
    });
    watch(sectors, () => {
      scrollToCenter();
    });

    const selectSeat = seat => {
      emit('select-seat', seat);
    };

    return {
      hallEl,
      sectors,
      selectSeat,
    };
  },
}
</script>

<style scoped>
.hall {
  background-color: #373A3C;
  overflow-x: auto;
}
.hall-container {
  display: flex;
}

.hall-block {
  margin: 0 auto;
}

.hall-stage {
  width: 70%;
  margin: 0 auto 32px;
  border-bottom: 2px #fff solid;
  padding: 32px 0 8px;
  text-align: center;
  color: #fff;
}

.hall-stage h2 {
  margin: 0 auto;
  justify-content: center;
}

.hall-exit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  font-size: 20px;
}

.seats {
  display: flex;
  justify-content: space-between;
}

.seats__out-sector {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

</style>