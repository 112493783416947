<template>
  <div class="row justify-content-center align-items-center mx-0" style="height: 100%">
    <div :class="`col-md-${$props.cardWidth}`">
      <div class="card bg-light">
        <header class="card-header" v-if="$props.title" style="background-color: #242424; color: #fff">
          <strong>{{ $props.title }}</strong>
        </header>
        <article class="card-body mx-0" style="background-color: #242424; color: #fff">
          <slot />
        </article>
      </div>
      <div v-if="$props.error" class="alert alert-danger mt-2" role="alert">
        {{ $props.error }}
      </div>
      <div v-if="$props.message" class="alert alert-success mt-2" role="alert">
        {{ $props.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    error: { type: String, default: '' },
    message: { type: String, default: '' },
    cardWidth: { type: Number, default: 3 }
  },
};
</script>