<template>
  <modal title="Confirm" @close="$emit('close')">
    <panorama v-if="seat.photoUrl" :source="seat.photoUrl" />
    <invite-info :event="event" :seat="seat" />
    <p class="m-0 h4 text-center"><strong>{{ invite.name }}</strong></p>
    <button class="btn btn-success btn-block mt-4" @click="confirm">Confirm</button>
  </modal>
</template>

<script>
import Modal from '../ui/Modal';
import Panorama from '../ui/Panorama.vue';
import InviteInfo from "@/components/admin/InviteInfo";
import { useInvites } from "@/store";

export default {
  name: 'ConfirmSeatModal',
  components: {
    InviteInfo,
    Modal,
    Panorama,
  },
  props: {
    invite: { type: Object, default: () => ({}) },
    seat: { type: Object, default: () => ({}) },
    event: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const { confirmInvite } = useInvites();
    const confirm = async () => {
      await confirmInvite(props.invite, props.seat);
      emit('confirm');
    };

    return {
      confirm,
    };
  }
}
</script>

<style scoped>

</style>