<template>
  <div class="panorama">
    <panolens style="height: 300px" :source="source" />
    <div class="panorama-icon" />
  </div>
</template>

<script>
import Panolens from './Panolens';

export default {
  name: 'Panorama',
  components: { Panolens },
  props: {
    source: {
      type: String,
      default: ''
    },
  }
};
</script>

<style scoped>
.panorama {
  position: relative;
}
.panorama-icon {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(../../assets/3d-rotate.svg);
  height: 34px;
  width: 34px;
  background-size: cover;
}
</style>