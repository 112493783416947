<template>
  <modal>
    <check-icon active class="mx-auto" style="margin-bottom: 8px"/>
    <p class="text-center"><strong>Thank you {{ invite.name }}</strong></p>
    <p class="text-center">Your reservation has been confirmed</p>
    <p class="text-center">#{{ invite.number }}/{{ event && event.date.replace(/-/g, '') }}<br>Reservation Number</p>
    <hr class="mb-0"/>
    <div class="row justify-content-around mt-1">
      <div class="date text-center">
        {{ date }}<br>
      </div>
      <div class="time text-center">
        {{ time }}<br>
      </div>
      <div class="person text-center">
        1 person
      </div>
    </div>
    <a class="btn btn-primary btn-block col-md-6 mt-4 ml-auto mr-auto" :href="calendarLink" target="_blank">
      Add to Calendar
    </a>
    <button class="btn btn-success btn-block col-md-6 mt-2 ml-auto mr-auto" @click="$emit('check-in')">
      QR Code
    </button>
    <button class="btn btn-danger btn-block col-md-6 mt-2 ml-auto mr-auto" @click="cancel">
      Change Seat
    </button>
    <div class="text-center mt-4" style="font-size: 14px">
      <p class="text-uppercase m-0 p-0">1-866-geneius</p>
      <p class="text-uppercase m-0 p-0">1-866-436-3487</p>
      <p class="text-uppercase m-0 p-0">info@askbillygene.com</p>
    </div>
  </modal>
</template>

<script>
import { computed } from 'vue';
import CheckIcon from '../ui/CheckIcon.vue';
import Modal from '@/components/ui/Modal'
import {useEvents, useLocations, useInvites} from '@/store'
import {formatDate, formatTime, getDateFromStr} from "@/lib/date";

export default {
  name: 'CheckInModal',
  components: { Modal, CheckIcon },
  props: {
    invite: { type: Object },
  },
  setup(props, { emit }) {
    const { getEventByKey } = useEvents();
    const event = computed(() => getEventByKey(props.invite.event));

    const eventDate = computed(() => {
      return event.value ? getDateFromStr(event.value.date) : new Date();
    });
    const date = computed(() => formatDate(eventDate.value, 'short'));
    const time = computed(() => formatTime(eventDate.value));

    const { getLocationByKey } = useLocations();
    const calendarLink = computed(() => {
      if (event.value) {
        const text = event.value.title.replace(/\s/g, '+');
        const date = eventDate.value.toISOString().replace(/[-:]/g, '');
        const location = getLocationByKey(event.value.location);
        return `https://calendar.google.com/calendar/u/0/r/eventedit?text=${text}&dates=${date}/${date}&location=${location && location.name}`;
      }
      return '';
    });

    const { cancelConfirmInvite } = useInvites();
    const cancel = async () => {
      await cancelConfirmInvite(props.invite);
      emit('close');
    };

    return {
      event,
      date,
      time,
      calendarLink,
      cancel,
    };
  }
}
</script>

<style scoped>
.date {
  flex: 1;
  border-right: 1px solid rgba(0,0,0,.1);
}
.time {
  flex: 1;
  border-right: 1px solid rgba(0,0,0,.1);
}
.person {
  flex: 1;
}
</style>