import { ref, readonly } from 'vue';
import { EVENTS_PATH } from "@/lib/api";
import { useListCrud } from "@/lib/listCrud";
import { getSeatStr } from "@/lib/seat";

export const eventsSymbol = Symbol('events');

export const createEventsStore = async () => {
  const events = ref([]);
  const { get, create, update } = await useListCrud(events, EVENTS_PATH);

  const addBlockOffSeat = async (seat, event) => {
    const blockOff = event.blockOff ? Array.from(event.blockOff) : [];
    const seatStr = getSeatStr(seat);
    if (!blockOff.includes(seatStr)) {
      blockOff.push(seatStr);
      await update(event.key, {
        ...event,
        blockOff,
      });
    }
  };

  const removeBlockOffSeat = async (seat, event) => {
    const blockOff = event.blockOff;
    await update(event.key, {
      ...event,
      blockOff: Array.from(blockOff).filter(s => s !== getSeatStr(seat)),
    });
  };

  const cancelEvent = async (event, reason) => {
    await update(event.key, {
      ...event,
      canceled: true,
      reason,
    });
  }

  return {
    events: readonly(events),
    createEvent: create,
    getEventByKey: get,
    addBlockOffSeat,
    removeBlockOffSeat,
    cancelEvent,
  };
}
