import { ref, readonly } from 'vue';
import { INVITES_PATH } from "@/lib/api";
import {useListCrud} from "@/lib/listCrud";

export const invitesSymbol = Symbol('invites');

export const createInvitesStore = async () => {
  const invites = ref([]);
  const { get, update, create } = await useListCrud(invites, INVITES_PATH);

  const cancelConfirmInvite = (invite) => invite.confirmed &&
    update(invite.key, { ...invite, confirmed: false, seat: null });

  const confirmInvite = (invite, seat = invite.seat) => !invite.confirmed &&
    update(invite.key, { ...invite, confirmed: true, seat });

  const addQrCodeLink = (invite, qrCodeLink) => !invite.qrCodeLink &&
    update(invite.key, { ...invite, qrCodeLink });

  const checkInInvite = async invite => await update(invite.key, { ...invite, checkedIn: true });

  const invitesByEvent = event => invites.value.filter(invite => invite.event === event.key);

  const removeInvite = async invite => await update(invite.key, {
    ...invite,
    confirmed: false,
    checkedIn: false,
    removed: true,
    seat: null,
  });


  return {
    invites: readonly(invites),
    confirmInvite,
    addQrCodeLink,
    createInvite: create,
    getInviteByKey: get,
    invitesByEvent,
    checkInInvite,
    cancelConfirmInvite,
    removeInvite,
  };
}
