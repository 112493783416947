<template>
  <auth-layout title="Sign In" :error="error" :message="successMessage">
    <form @submit.prevent="signIn">
      <div class="form-group">
        <!-- <div class="input-group-prepend">
          <span class="input-group-text">Email</span>
        </div> -->
        <label for="email-input">Email</label>
        <input
          id="email-input"
          v-model="email"
          class="form-control"
          placeholder="example@mail.com"
          type="email"
          @input="clearError"
        >
      </div>
      <div class="form-group">
        <label for="password-input">Password</label>
        <!-- <div class="input-group-prepend">
          <span class="input-group-text">Password</span>
        </div> -->
        <input
          id="password-input"
          v-model="password"
          class="form-control"
          placeholder="Password"
          type="password"
          @input="clearError"
        >
      </div>
      <button type="submit" class="btn btn-primary m-0 pl-4 pr-4">Sign In</button>
      <div class="text-right mt-1" style="font-size: 0.9rem">
        <router-link to="/reset-password">
          Forgot your password?
        </router-link>
        <br>
<!--        Don't have an account? <router-link to="/sign-up">Sign Up</router-link>-->
      </div>
    </form>
  </auth-layout>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import AuthLayout from './AuthLayout.vue';
import { auth } from '../../lib/api';

export default {
  name: 'SignIn',
  components: { AuthLayout },
  setup() {
    const email = ref('');
    const password = ref('');
    const error = ref('');

    const router = useRouter();
    const signIn = async () => {
      try {
        await auth.signInWithEmailAndPassword(email.value, password.value);
        router.push({ name: 'admin' });
      } catch (e) {
        error.value = e.message;
      }
    };

    const successMessage = ref('');
    const route = useRoute();
    onBeforeMount(() => {
      const { reset } = route.query;
      if (reset) {
        successMessage.value = 'Instructions have been sent to your email';
        router.replace({ name: 'sign-in' });
      }
    });

    const clearError = () => {
      error.value = '';
      successMessage.value = '';
    };

    return {
      email,
      password,
      error,
      successMessage,
      signIn,
      clearError,
    };
  },
}
</script>
